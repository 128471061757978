import i18n from "i18next";
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LANGUAGES, baseUrl } from "./Constants/Constant";

const langType = localStorage.getItem("lang_type");

i18n
  .use(i18nBackend)
  .use(initReactI18next)
  .init({
    lng: !langType ? LANGUAGES.JP : langType,
    fallbackLng: LANGUAGES.JP, 
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${baseUrl}bookingportal/locale/{{lng}}`,
      customHeaders: {
          "Accept-Language": langType || LANGUAGES.JP, // Explicitly set Accept-Language
      },
    },
    supportedLngs: [LANGUAGES.EN, LANGUAGES.JP], 
  });
console.log("langTypeHello", langType);
export default i18n;
